import React, { useEffect, useMemo, useState } from "react";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import {
  Box,
  Button,
  Container,
  CssBaseline,
  Link,
  Typography,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useHistory } from "react-router-dom";
import SiccLayout from "./SiccLayout";
import axios from "axios";

function CustomToolbar() {
  return (
    <GridToolbarContainer
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: 2,
      }}
    >
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

const mockData = [
  {
    senderId: "1b66dbcc-1058-4652-bf61-8cbf47e2f97a",
    firstName: "John",
    lastName: "Doe",
    siteName: "ABC Senior Living",
    siteId: "73",
    ipAddress: "101.101.101.101",
  },
  {
    senderId: "dff4d175-bf64-4a91-a83f-e654dea48a3e",
    firstName: "Bill",
    lastName: "Gates",
    siteName: "AJ Java Joint",
    siteId: "89",
    ipAddress: "101.101.101.102",
  },
  {
    senderId: "4040c0d5-d6cb-4712-883c-9bed47f247b3",
    firstName: "Elon",
    lastName: "Musk",
    siteName: "ABC Senior Living",
    siteId: "73",
    ipAddress: "101.101.101.103",
  },

  {
    senderId: "91cd4ea1-8efe-4d87-a556-d325c60c2644",
    firstName: "Emma",
    lastName: "Watson",
    siteName: "AJ Keto Factory",
    siteId: "61",
    ipAddress: "101.101.101.104",
  },
  {
    senderId: "91cd4ea1-8efe-4d87-a556-d325c60c2644",
    firstName: "Andrew",
    lastName: "Simmons",
    siteName: "ABC Senior Living",
    siteId: "73",
    ipAddress: "101.101.101.105",
  },
];

const ConversationList = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [conversations, setConversations] = useState([]);
  // Pull from conversations API
  const getConversations = async () => {
    try {
      const { data } = await axios.get(
        "https://9fbd3007bb62.ngrok.app/conversations"
      );
      const newData = data.reverse().map((item, index) => {
        const match = mockData.find((user) => user.senderId === item.sender_id);
        if (match) return { ...match, id: index };

        return {
          id: index,
          senderId: item.sender_id,
          firstName: "John",
          lastName: "Doe",
          siteName: "ABC Senior Living",
          siteId: "73",
          ipAddress: "101.101.101.105",
        };
      });
      setConversations(newData);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getConversations();
  }, []);

  return (
    <SiccLayout>
      <Box>
        <Box
          sx={{
            minHeight: "100vh",
            display: "flex",
            justifyContent: "flex-start",
            width: "100%",
          }}
        >
          <Container maxWidth="lg">
            <Box sx={{ my: 2 }}>
              <Typography
                variant="h5"
                fontWeight="bold"
                textTransform="uppercase"
              >
                Ruby AI Conversation
              </Typography>
            </Box>
            <Box sx={{ height: 600, width: "100%" }}>
              <DataGrid
                rows={conversations}
                columns={[
                  {
                    headerName: "Sender ID",
                    flex: 1,
                    field: "senderId",
                    renderCell: (params) => (
                      <Link href={"/conversations/" + params.value}>
                        {params.value}
                      </Link>
                    ),
                  },
                  {
                    headerName: "First Name",
                    flex: 1,
                    field: "firstName",
                    align: "center",
                    headerAlign: "center",
                  },
                  {
                    headerName: "Last Name",
                    flex: 1,
                    field: "lastName",
                    align: "center",
                    headerAlign: "center",
                  },
                  {
                    headerName: "Site Name",
                    flex: 1,
                    field: "siteName",
                    align: "center",
                    headerAlign: "center",
                  },
                  {
                    headerName: "Site ID",
                    width: 80,
                    field: "siteId",
                    align: "center",
                    headerAlign: "center",
                  },
                  {
                    headerName: "IP Address",
                    flex: 1,
                    field: "ipAddress",
                    align: "center",
                    headerAlign: "center",
                  },
                ]}
                slots={{
                  toolbar: CustomToolbar,
                }}
                loading={loading}
                sx={{
                  width: "100%",
                  padding: 2,
                }}
              />
            </Box>
          </Container>
        </Box>
      </Box>
    </SiccLayout>
  );
};

export default ConversationList;
