import React, { useEffect, useState } from "react";
import {
  Typography,
  Button,
  useMediaQuery,
  IconButton,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { QRDialog } from "./QrDialog";
import { NotificationBell } from "../eComm";
import { useSelector } from "react-redux";
import background from "../../assets/images/homescreen-bg.png";
import logo from "../../assets/images/logo.svg";

export const HomeScreen = () => {
  const [openCamera, setOpenCamera] = useState(false);
  const firstName = sessionStorage.getItem("firstName") || null;
  const { user } = useSelector((state) => state.public);
  const history = useHistory();
  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <>
      <div
        style={{ position: "fixed", top: "20px", right: "20px", zIndex: 100 }}
      >
        {user && <NotificationBell />}
      </div>
      <div
        style={{
          height: "100vh",
          width: "100vw",
          background: `linear-gradient(rgba(255,255,255,.05), rgba(0,0,0,.4)), url(${background})no-repeat center center`,
          color: "white",
          position: "fixed",
          top: "0px",
          left: "0px",
          padding: "2rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            marginBottom: "1rem",
          }}
        >
          <Typography>
            <img src={logo} style={{ width: "200px" }} />
          </Typography>
        </div>
        <Typography
          variant={isMobile ? "p" : "h6"}
          style={{
            textAlign: "center",
            fontWeight: "500",
            marginBottom: "1rem",
            maxWidth: "500px",
            color: "white",
          }}
        >
          Discover a Life of Comfort and Fulfillment in Our Senior Living
          Community
        </Typography>
        <div>
          {firstName ? (
            <>
              <Button
                variant="contained"
                style={{
                  borderRadius: "15px",
                  marginTop: "1rem",
                  padding: "1rem 2rem",
                  backgroundColor: "rgb(10, 61, 98)",
                  color: "white",
                  width: "100%",
                  fontSize: "1.5rem",
                  fontWeight: "500",
                }}
                onClick={() => {
                  history.push("/welcome");
                }}
              >
                In-room Dining
              </Button>
            </>
          ) : (
            <>
              <Button
                variant="contained"
                style={{
                  borderRadius: "15px",
                  marginTop: "1rem",
                  padding: "1rem 2rem",
                  backgroundColor: "rgb(10, 61, 98)",
                  color: "white",
                  width: "100%",
                  fontSize: "1.5rem",
                  fontWeight: "500",
                }}
                onClick={() => {
                  history.push("/home");
                }}
              >
                Login
              </Button>
            </>
          )}
          <button
            className="MuiButton-root "
            style={{
              borderRadius: "15px!important",
              marginTop: "1rem",
              padding: "1rem 2rem",
              width: "100%",
              border: "1px solid #FF007F",
              background: "#FF007F",
              color: "#00000 ! important",
              position: "relative",
            }}
            onClick={() => {
              history.replace("/askme", {
                from: history.location.pathname,
              });
            }}
          >
            <div
              style={{
                color: "#ffff",
                fontWeight: "500",
                fontSize: "1.5rem",
              }}
            >
              Ruby AI
            </div>
            <div
              className={`pulse`}
              style={{
                background: "#ffff",
                borderRadius: 0,
                height: "110%",
              }}
            ></div>
          </button>
        </div>
      </div>
    </>
  );
};
