import axios from "axios";
import React, { useCallback, useEffect, useRef, useState } from "react";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import {
  alpha,
  Box,
  Button,
  Chip,
  Container,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { ArrowBack, Delete, Girl, Send } from "@mui/icons-material";
import useTts from "../../hooks/useTts";
import { Loader } from "components";
import { useHistory } from "react-router";
import {
  AudioConfig,
  SpeechConfig,
  SpeechSynthesizer,
} from "microsoft-cognitiveservices-speech-sdk";
import { enableRevenueCenter, serverId, siteId, storeId } from "utils/helpers";
import { setLoading } from "redux/publicReducer/actions";
import { Divider } from "@material-ui/core";
import { v4 as uuidV4 } from "uuid";
import { rubyErrorMessage } from "utils/constants";

const greetings = () => {
  const currentTime = new Date();
  const hours = currentTime.getHours();

  if (hours >= 4 && hours < 12) {
    return "morning";
  } else if (hours >= 12 && hours < 18) {
    return "afternoon";
  } else {
    return "evening";
  }
};

const findPhrase = (sentence, phrase) => {
  const regex = new RegExp(phrase, "i");
  const match = sentence.match(regex);
  console.log(match, sentence, phrase);
  return !!match;
};

const Askme = () => {
  const senderId = localStorage.getItem("sender_id");
  const firstName = sessionStorage.getItem("firstName") || "";
  const [text, setText, loading, duration, stopSpeaking] = useTts();
  const [image, setImage] = useState("");
  const [isTimeout, setIsTimeout] = useState(false);
  const [inputVal, setInputVal] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [availableProducts, setAvailableProducts] = useState([]);
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
    finalTranscript,
  } = useSpeechRecognition();
  const history = useHistory();
  const timer = useRef(null);

  const messagesEndRef = useRef(null);

  useEffect(() => {
    const initMessage = `Hello Good ${greetings()} ${firstName}, how may I help you?`;

    const apiUrl = enableRevenueCenter == 1 ? "all-revenue-center" : "all";
    const url =
      "https://ecommv2.servingintel.com/items/" +
      apiUrl +
      "/" +
      siteId +
      "?server_id=" +
      serverId;

    axios
      .get(url)
      .then((response) => {
        if (availableProducts !== response.data) {
          setAvailableProducts(response.data);
        }
      })
      .catch((error) => {});
    axios
      .get(`https://9fbd3007bb62.ngrok.app/conversations/${senderId}`)
      .then(({ data }) => {
        setMessages([
          ...data,
          {
            sender: "ruby",
            message: initMessage,
          },
        ]);
      })
      .catch((error) => {
        console.log(error, "err");
      });

    if (typeof window !== "undefined" && !loading) {
      const speechConfig = SpeechConfig.fromSubscription(
        "061979f756dc4e8c9280619317e3283f",
        "centralus"
      );

      speechConfig.speechSynthesisVoiceName = "en-US-JennyNeural";
      const audioConfig = AudioConfig.fromDefaultSpeakerOutput();
      const synthesizer = new SpeechSynthesizer(speechConfig, audioConfig);

      synthesizer.speakTextAsync(
        initMessage,
        (result) => {
          if (result.errorDetails) {
            console.error(result.errorDetails);
          }

          setTimeout(() => {
            SpeechRecognition.startListening({ continuous: true });
          }, Math.round(result.audioDuration / 10000));
        },
        (err) => console.log(err)
      );
    }
  }, [loading, firstName]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  useEffect(() => {
    if (finalTranscript) {
      if (timer.current) {
        clearTimeout(timer.current);
      }

      timer.current = setTimeout(() => {
        setIsTimeout(true);
      }, 1000);
    }
  }, [finalTranscript]);

  useEffect(() => {
    if (transcript) {
      setInputVal(transcript);
      setIsLoading(true);
    }
  }, [transcript]);

  useEffect(() => {
    if (duration === 0) return;
    const speakTimeout = setTimeout(() => {
      SpeechRecognition.startListening({ continuous: true });
    }, duration); // Add .5 sec to the duration

    return () => clearTimeout(speakTimeout);
  }, [duration]);

  const apiCall = useCallback(() => {
    SpeechRecognition.stopListening();
    resetTranscript();

    // If no keywords order found call rasa
    if (!findPhrase(finalTranscript, "order")) return callRasa();
    let url = "https://ecommv2.servingintel.com/items";

    if (findPhrase(finalTranscript, "order breakfast")) {
      url +=
        "/revenue-center-serving-categories/" +
        serverId +
        "/" +
        siteId +
        "/none/Las Olivas/Breakfast Menu";
      return callRestaurant(
        url,
        "Please click the breakfast item that you want to order",
        "DepartmentDescription",
        "DepartmentName"
      );
    } else if (findPhrase(finalTranscript, "order lunch")) {
      url +=
        "/revenue-center-serving-categories/" +
        serverId +
        "/" +
        siteId +
        "/none/Las Olivas/Lunch Menu";
      return callRestaurant(
        url,
        "Please click the lunch item that you want to order",
        "DepartmentDescription",
        "DepartmentName"
      );
    } else if (findPhrase(finalTranscript, "order dinner")) {
      url +=
        "/revenue-center-serving-categories/" +
        serverId +
        "/" +
        siteId +
        "/none/Las Olivas/Dinner Menu";
      return callRestaurant(
        url,
        "Please click the dinner item that you want to order",
        "DepartmentDescription",
        "DepartmentName"
      );
    } else {
      url += "/revenue-menu/" + serverId + "/" + siteId + "/Las Olivas";
      return callRestaurant(url, "Please select from the menu.", "MenuName");
    }
  }, [resetTranscript, finalTranscript, setText]);

  const callRasa = async () => {
    try {
      const { data } = await axios.post(
        "https://a6638cae24f8.ngrok.app/webhooks/rest/webhook",
        {
          sender: senderId || "default",
          message: finalTranscript,
        }
      );

      setText(
        data[0]?.text ? data[0]?.text : "Sorry, I am not able to answer that."
      );
      const newMessages = [];

      const foundImage = data.find((item) => "image" in item);
      newMessages.push(
        { sender: "user", message: transcript },
        {
          sender: "ruby",
          message: data[0]?.text
            ? data[0]?.text
            : "Sorry, I am not able to answer that.",
        }
      );

      if (foundImage) {
        setImage(foundImage.image);
        newMessages.push({ sender: "ruby", image: foundImage.image });
      }
      setMessages((prev) => [...prev, ...newMessages]);
    } catch (error) {
      setText(rubyErrorMessage?.message);
      setMessages((prev) => [...prev, rubyErrorMessage]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSend = async () => {
    setIsLoading(true);
    SpeechRecognition.stopListening();
    try {
      const { data } = await axios.post(
        "https://a6638cae24f8.ngrok.app/webhooks/rest/webhook",
        {
          sender: senderId || "default",
          message: inputVal,
        }
      );
      setText(
        data[0]?.text ? data[0]?.text : "Sorry, I am not able to answer that."
      );
      const newMessages = [];

      const foundImage = data.find((item) => "image" in item);
      newMessages.push(
        { sender: "user", message: inputVal },
        {
          sender: "ruby",
          message: data[0]?.text
            ? data[0]?.text
            : "Sorry, I am not able to answer that.",
        }
      );

      if (foundImage) {
        setImage(foundImage.image);
        newMessages.push({ sender: "ruby", image: foundImage.image });
      }
      setMessages((prev) => [...prev, ...newMessages]);
    } catch (error) {
      console.log(error, "Set a message error");
      setText(rubyErrorMessage?.message);
      setMessages((prev) => [...prev, rubyErrorMessage]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClear = async () => {
    localStorage.setItem("sender_id", uuidV4());
    setMessages([]);
  };

  const callRestaurant = async (url, message, key, filterKey) => {
    try {
      const { data } = await axios.get(url);

      if (filterKey) {
        const products = availableProducts.filter((prod) =>
          data.map((item) => item[filterKey]).includes(prod.Department)
        );
        setMessages((prev) => [
          ...prev,
          { sender: "ruby", message },
          { sender: "ruby", products },
        ]);
      } else {
        setMessages((prev) => [
          ...prev,
          { sender: "ruby", message },
          { sender: "ruby", menus: data.map((item) => item[key]) },
        ]);
      }
      setText(message);
    } catch (error) {
      console.log(error, "Set a message error");
      setText(rubyErrorMessage?.message);
      setMessages((prev) => [...prev, rubyErrorMessage]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isTimeout) {
      apiCall();
      setImage("");
      setIsTimeout(false);
    }
  }, [isTimeout, callRasa]);

  const isMobile = useMediaQuery("(max-width:600px)");

  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }

  return (
    <Box>
      <Box
        sx={{
          padding: 2,
        }}
      >
        <Button
          onClick={() => {
            SpeechRecognition.stopListening();
            history.replace(history?.location?.state?.from || "/");
          }}
          startIcon={<ArrowBack />}
        >
          Back
        </Button>
      </Box>
      <Box
        sx={{
          minHeight: "100vh",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "100%",
        }}
      >
        {!loading ? (
          <Container maxWidth="sm">
            <Typography
              fontWeight={isMobile ? "bold" : ""}
              variant={isMobile ? "h4" : "h3"}
              textAlign="center"
            >
              Hi, I'm Ruby!
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <IconButton
                onClick={() => {
                  if (listening) {
                    SpeechRecognition.stopListening();
                  } else {
                    SpeechRecognition.startListening({ continuous: true });
                  }
                }}
                size="large"
                sx={{
                  position: "relative",
                  background: (theme) => theme.palette.grey[200],
                }}
              >
                {listening ? (
                  <Girl
                    sx={{
                      fontSize: isMobile ? "100px" : "100px",
                      color: "#FF007F",
                    }}
                  />
                ) : (
                  <Girl
                    sx={{
                      fontSize: isMobile ? "100px" : "100px",
                      color: "#FF007F",
                    }}
                  />
                )}
                <div
                  className={`${listening ? "pulse" : ""}`}
                  style={{ background: "#FF007F" }}
                ></div>
              </IconButton>
            </Box>
            <Box
              sx={{
                width: "100%",
                background: "white",
                border: "1px solid rgba(0,0,0,.2)",
                borderRadius: 5,
                marginTop: 2,
              }}
            >
              <Box
                borderBottom={1}
                padding={2}
                borderColor={(theme) => theme.palette.grey[400]}
              >
                <Typography variant="h5" component="div">
                  Conversation History
                </Typography>
              </Box>
              <Box
                padding={2}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: 300,
                  overflowY: "auto",
                  gap: 2,
                }}
              >
                {messages.map((item, index) =>
                  item.sender === "user" ? (
                    <Box
                      key={index}
                      bgcolor={(theme) =>
                        alpha(theme.palette.primary.main, 0.15)
                      }
                      paddingY={1}
                      paddingX={2}
                      sx={{
                        borderTopLeftRadius: 25,
                        borderTopRightRadius: 25,
                        borderBottomLeftRadius: 25,
                        alignSelf: "end",
                      }}
                      maxWidth={"80%"}
                    >
                      <Typography variant="h5" component="div">
                        {item.message}
                      </Typography>
                    </Box>
                  ) : (
                    <Box
                      bgcolor={item.products?.length > 0 ? "white" : "#FF007F"}
                      paddingY={1}
                      paddingX={2}
                      width="max-content"
                      maxWidth={"80%"}
                      sx={{
                        color: "white",
                        borderTopLeftRadius: 25,
                        borderTopRightRadius: 25,
                        borderBottomRightRadius: 25,
                        border: "1px solid #FF007F",
                      }}
                    >
                      <Typography variant="h5" component="div">
                        {item.message}
                      </Typography>
                      {item?.image && (
                        <img
                          style={{ maxWidth: "50%", textAlign: "center" }}
                          src={item?.image}
                          alt="Ruby Image"
                        />
                      )}
                      {item?.menus && (
                        <>
                          {item.menus.length > 0 ? (
                            <Stack gap={1} direction="row" flexWrap={"wrap"}>
                              {item.menus.map((menu, index) => (
                                <Chip
                                  label={menu}
                                  variant="outlined"
                                  sx={{
                                    background: "white",
                                    borderColor: "#FF007F",
                                  }}
                                />
                              ))}
                            </Stack>
                          ) : (
                            <Typography variant="h5" component="div">
                              No items on the menu
                            </Typography>
                          )}
                        </>
                      )}

                      {item?.products && (
                        <>
                          {item.products.length > 0 ? (
                            <Stack gap={1} direction="row" flexWrap={"wrap"}>
                              {item.products.map((menu, index) => (
                                <Chip
                                  label={menu.ItemName}
                                  variant="outlined"
                                  sx={{
                                    background: "white",
                                    borderColor: "#FF007F",
                                  }}
                                />
                              ))}
                            </Stack>
                          ) : (
                            <Typography variant="h5" component="div">
                              No items on the menu
                            </Typography>
                          )}
                        </>
                      )}
                    </Box>
                  )
                )}

                <div ref={messagesEndRef} style={{ position: "relative" }}>
                  {duration > 0 && !listening && (
                    <Box
                      sx={{
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        right: 0,
                        display: "grid",
                        placeItems: "center",
                      }}
                    >
                      <button
                        style={{
                          maxWidth: "max-content",
                          width: "100%",
                          textTransform: "uppercase",
                          fontSize: "1rem",
                          backgroundColor: "transparent",
                          border: "1px solid #0e0e0e",
                          color: "black!important",
                          padding: "4px 8px",
                        }}
                        variant="outlined"
                        onClick={() => window.location.reload()}
                      >
                        Stop Speaking
                      </button>
                    </Box>
                  )}
                </div>
              </Box>

              <Stack
                sx={{
                  display: "flex",
                  alignItems: "stretch",
                  flexDirection: "row",
                  width: "100%",
                  padding: 2,
                }}
              >
                <TextField
                  fullWidth
                  placeholder="Type here your questions"
                  variant="outlined"
                  value={inputVal}
                  onChange={(e) => setInputVal(e.target.value)}
                  sx={{ border: "1px solid #cecece", borderRadius: 2 }}
                />

                <Button
                  disabled={isLoading}
                  onClick={handleSend}
                  sx={{
                    borderRadius: 1,
                  }}
                >
                  {isLoading ? (
                    <div className="dot-loader">
                      <div className="dot"></div>
                      <div className="dot"></div>
                      <div className="dot"></div>
                    </div>
                  ) : (
                    "Send"
                  )}
                </Button>

                <Tooltip title="Clear Conversations">
                  <IconButton
                    onClick={handleClear}
                    size="large"
                    sx={{
                      marginLeft: 1,
                      background: "#ff3d3d",
                      borderRadius: 1,
                      "&:hover": {
                        background: "#f49a9a",
                      },
                    }}
                  >
                    <Delete
                      sx={{
                        color: "white",
                      }}
                    />
                  </IconButton>
                </Tooltip>
              </Stack>
            </Box>
          </Container>
        ) : (
          <Loader open={loading} />
        )}
      </Box>
    </Box>
  );
};
export default Askme;
