import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Logo from "../../../assets/images/logo.png";
// import Logo from "../../../assets/images/AbcSL.png";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
// import { Breadcrumbs, Link } from '@material-ui/core';
import Breadcrumbs from "./Breadcrumbs";
import { capitalize } from "utils/helpers";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useHistory } from "react-router";

const serverId = process.env.REACT_APP_SERVER_ID;
const siteId = process.env.REACT_APP_SITE_ID;
const site = process.env.REACT_APP_SITE;
const storeId = process.env.REACT_APP_STORE_ID;

export const MyBalanceDialog = ({
  open = false,
  setOpen,
  mealBalance = 0,
  mBalance = 0,
}) => {
  return (
    <Dialog maxWidth="sm" fullWidth open={open} onClose={() => setOpen(false)}>
      <DialogTitle>My Balance</DialogTitle>
      <DialogContent>
        <Typography component="h2" className="logoCenterBalance">
          <b>Meal Credit Available: </b>
          <b className="logoAmount">{mealBalance}</b> <br />
          <b>Current Balance: </b>
          <b className="logoAmount">${mBalance}</b>{" "}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          style={{ borderRAdius: "15px" }}
          onClick={() => setOpen(false)}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default function LogoCenter({ selectedRevenueCenter }) {
  const history = useHistory();
  const [mealBalance, setMealBalance] = useState();
  const [mealBalanceDollars, setMealBalanceDollars] = useState();
  const mBal = Math.abs(mealBalanceDollars / 100);
  const [openDialog, setOpenDialog] = useState(false);
  const mBalance = mBal.toFixed(2);
  const [firstName, setFirstName] = useState(
    capitalize(sessionStorage.getItem("firstName")?.toLowerCase() || "")
  );
  const [lastName, setLastName] = useState(
    capitalize(sessionStorage.getItem("lastName")?.toLowerCase() || "")
  );
  const [roomNumber, setRoomNumber] = useState(
    sessionStorage.getItem("roomNumber")
  );
  const loginCustomerNumber = process.env.REACT_APP_LOGIN_CUSTOMER_NUMBER;

  useEffect(() => {
    const url =
      loginCustomerNumber == 1
        ? "https://ecommv2.servingintel.com/customer/login-customer-number/" +
          serverId +
          "/" +
          siteId +
          "/" +
          firstName +
          "/" +
          roomNumber
        : "https://ecommv2.servingintel.com/customer/login/" +
          serverId +
          "/" +
          siteId +
          "/" +
          storeId +
          "/" +
          firstName +
          "/" +
          roomNumber;
    axios
      .get(url)
      .then((response) => {
        const customer = response.data;
        console.log(customer, "cust");
        if (
          customer.FirstName.toLowerCase() === firstName.toLowerCase() &&
          (customer.Address1.toLowerCase() === roomNumber.toLowerCase() ||
            customer.CustomerNumber == roomNumber)
        ) {
          setMealBalance(customer.MealCount);
          setMealBalanceDollars(
            customer.CreditLimit - customer.AvailableCredit
          );
        } else {
          window.location.href = site;
        }
      })
      .catch((error) => {});
  }, []);

  const useStyles = makeStyles((theme) => ({
    sLHeader: {
      position: "sticky",
      top: 0,
      backgroundColor: theme.palette.background.default,
    },
  }));
  const classes = useStyles();
  return (
    <div className="LogoMenuHeader">
      <MyBalanceDialog
        open={openDialog}
        setOpen={setOpenDialog}
        mealBalance={mealBalance}
        mBalance={mBalance}
      />
      <Toolbar
        sx={{ display: "flex" }}
        className={classes.sLHeader}
        id="headerLogoSticky"
      >
        <Box
          id="logo-center"
          sx={{
            mx: "auto",
            width: "auto",
            p: 0.5,
            // bgcolor: (theme) =>
            //   theme.palette.mode === "dark" ? "#101010" : "grey.50",
            // color: (theme) =>
            //   theme.palette.mode === "dark" ? "grey.300" : "grey.800",
            // border: "1px solid",
            borderColor: (theme) =>
              theme.palette.mode === "dark" ? "grey.800" : "grey.300",
            borderRadius: 2,
            textAlign: "center",
            fontSize: "0.875rem",
            fontWeight: "700",
            justifyContent: "flex-start",
          }}
        >
          <img
            className="App-logo"
            src={Logo}
            alt="Premier Memory Care | The Terraces at Fountaingrove Lodge"
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 2,
            marginLeft: 2,
            marginBottom: {
              xs: 2,
              lg: 0,
            },
          }}
        >
          <Typography>
            Welcome back {firstName} {lastName}!
          </Typography>
        </Box>
      </Toolbar>
      <div className="heroBannerImage"></div>
      <Breadcrumbs selectedRevenueCenter={selectedRevenueCenter} />
      <div className="userInfo">
        <Box
          id="transparentCustomUser"
          sx={{
            mx: "auto",
            width: "auto",
            p: 0.5,
            bgcolor: (theme) =>
              theme.palette.mode === "dark" ? "#101010" : "grey.50",
            color: (theme) =>
              theme.palette.mode === "dark" ? "grey.300" : "grey.800",
            border: "1px solid",
            borderColor: (theme) =>
              theme.palette.mode === "dark" ? "grey.800" : "grey.300",
            borderRadius: 2,
            textAlign: "left",
            fontSize: "0.875rem",
            fontWeight: "700",
            marginTop: "5px",
            marginBottom: "5px",
            marginLeft: "38px",
            order: 2,
          }}
        >
          <Typography component="div" align="left">
            Good day <b className="logoAmount">{firstName}</b>. How can we serve
            you today?{" "}
          </Typography>
        </Box>
        <Box
          id="transparentCustomBal"
          sx={{
            mx: "auto",
            width: "auto",
            p: 0.5,
            bgcolor: (theme) =>
              theme.palette.mode === "dark" ? "#101010" : "grey.50",
            color: (theme) =>
              theme.palette.mode === "dark" ? "grey.300" : "grey.800",
            border: "1px solid",
            borderColor: (theme) =>
              theme.palette.mode === "dark" ? "grey.800" : "grey.300",
            borderRadius: 2,
            textAlign: "left",
            fontSize: "0.575rem",
            fontWeight: "700",
            marginTop: "5px",
            marginBottom: "5px",
            marginLeft: "40px",
            marginRight: "73px",
            order: 3,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              maxWidth: "max-content",
            }}
          >
            <button
              className="MuiButton-root "
              style={{
                borderRadius: "15px!important",
                padding: ".5rem 2rem",
                width: "100%",
                maxWidth: "max-content",
                border: "1px solid #005cb9",
                background: "#005cb9",
                color: "#00000 ! important",
                position: "relative",
              }}
              onClick={() => {
                setOpenDialog(true);
              }}
            >
              <div
                style={{
                  color: "#ffff",
                  fontWeight: "500",
                }}
              >
                My Balance
              </div>
            </button>
            <button
              className="MuiButton-root "
              style={{
                borderRadius: "15px!important",
                padding: ".5rem 2rem",
                width: "100%",
                maxWidth: "max-content",
                border: "1px solid #FF007F",
                background: "#FF007F",
                color: "#00000 ! important",
                position: "relative",
              }}
              onClick={() => {
                history.replace("/askme", { from: history.location.pathname });
              }}
            >
              <div
                style={{
                  color: "#ffff",
                  fontWeight: "500",
                }}
              >
                Ruby AI
              </div>
              <div
                className={`pulse`}
                style={{
                  background: "#ffff",
                  borderRadius: 0,
                  height: "110%",
                }}
              ></div>
            </button>
          </Box>
          {/*<Typography>
                Meal Points Available
                <Typography variant="h5" component="div" align="center" style={{fontWeight:'800'}}>*/}
          {/*mealBalance*/}
          {/* </Typography>
              </Typography> */}
        </Box>
      </div>
    </div>
  );
}
